<template>
  <v-container>
    <div>
      <h1 v-html="$t('search_milk_quality_table')"></h1>

      <v-form ref="searchQuality">
        <v-row class="row search spacer-md">
          <v-col xs="6" md="4" lg="2">
            <search-milk-quality-period ref="search-milk-quality-period" @search="search" :validCustomRule="validAdditionalRule" />
          </v-col>
          <v-col xs="6" md="4" lg="2">
            <search-farm ref="search-farm" @search="search" />
          </v-col>
          <v-col xs="6" md="4" lg="2">
            <search-contact ref="search-contact" @search="search" />
          </v-col>
          <v-col xs="6" md="4" lg="2">
            <search-milk-quality-type ref="search-milk-quality-type" @search="search" />
          </v-col>
          <v-col xs="6" md="4" lg="2">
            <search-milk-quality-value ref="search-milk-quality-value" @search="search" />
          </v-col>
          <v-col xs="6" md="4" lg="2">
            <search-milk-quality-rating ref="search-milk-quality-rating" @search="search" />
          </v-col>
        </v-row>
        <v-row class="d-print-none">
          <v-col>
            <v-btn color="primary" :loading="loading" @click="search"><span v-html="$t('search_milk_quality_search')" /></v-btn>
            <v-btn color="secondary" @click="reset"><span v-html="$t('search_milk_quality_reset')" /></v-btn>
            <span class="m-2">
              <v-menu class="m-2" offset-y>
                <template v-slot:activator="{ props }">
                  <v-btn id="formular" color="secondary" v-bind="props" :loading="downloading">
                    <span v-html="$t('milk_qualities_export_download')" />
                    <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item id="print_btn" key="milk_qualities_export_csv" @click="exportCsv">
                    <v-list-item-title><span v-html="$t('milk_qualities_export_csv')" /></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $privileges.has({
                        path: '/milkQualities/excel',
                        permission: 'withDeductions'
                      })
                    "
                    id="export_btn"
                    key="milk_qualities_export_with_deduction_or_addition"
                    @click="exportWithDeductionOrAddition"
                  >
                    <v-list-item-title><span v-html="$t('milk_qualities_export_with_deduction_or_addition')" /></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $privileges.has({
                        path: '/milkQualities/excel',
                        permission: 'withoutDeductions'
                      })
                    "
                    id="print_btn"
                    key="milk_qualities_export_without_deduction_or_addition"
                    @click="exportWithoutDeductionOrAddition"
                  >
                    <v-list-item-title><span v-html="$t('milk_qualities_export_without_deduction_or_addition')" /></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $privileges.has({
                        path: '/milkQualities/excel',
                        permission: 'forAuthority'
                      })
                    "
                    id="update_btn"
                    key="milk_qualities_export_for_office"
                    @click="exportForAuthority"
                  >
                    <v-list-item-title><span v-html="$t('milk_qualities_export_for_office')" /></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $privileges.has({
                        path: '/milkQualities/csv',
                        permission: 'read'
                      })
                    "
                    id="milk_qualities_summer_report_no_surcharge"
                    key="milk_qualities_summer_report_no_surcharge"
                    @click="exportSummaryReport('pdfWithoutDeductions')"
                  >
                    <v-list-item-title><span v-html="$t('milk_qualities_summer_report_no_surcharge')" /></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $privileges.has({
                        path: '/milkQualityRatings',
                        permission: 'read'
                      })
                    "
                    id="milk_qualities_summer_report_with_surcharge"
                    key="milk_qualities_summer_report_with_surcharge"
                    @click="exportSummaryReport('pdfWithDeductions')"
                  >
                    <v-list-item-title><span v-html="$t('milk_qualities_summer_report_with_surcharge')" /></v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="
                      $privileges.has({
                        path: '/milkQualities/excel',
                        permission: 'forAuthority'
                      })
                    "
                    id="milk_qualities_export_for_authority"
                    key="milk_qualities_export_for_authority"
                    @click="exportAuthorityReport()"
                  >
                    <v-list-item-title><span v-html="$t('milk_qualities_export_for_authority')" /></v-list-item-title>
                  </v-list-item>
                  <v-list-item id="milk_qualities_report_pdf" key="milk_qualities_report_pdf" @click="labReportsPdf">
                    <v-list-item-title><span v-html="$t('milk_qualities_report_pdf')" /></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
          </v-col>
        </v-row>
        <v-row>
          <div>
            <v-switch
              class="align-right"
              id="search_milk_quality_reduced"
              v-model="reducedColumns"
              color="primary"
              :label="reducedColumns ? $t('search_milk_quality_reduced') : $t('search_milk_quality_show_all')"
            ></v-switch>
          </div>
        </v-row>
      </v-form>
    </div>

    <div>
      <v-data-table-server
        :multi-sort="true"
        density="compact"
        :headers="fields"
        :items="items"
        class="zero-width d-print-table"
        :fields="fields"
        :loading="loading"
        :items-length="totalElements"
        @update:itemsPerPage="routerPushSize"
        @update:page="routerPushPage"
        @update:sort-by="routerPushDTSort"
        :sort-by="toDTSort(query.sort)"
        :page="getDTPageFromRoute(query.page)"
        :items-per-page="query.size"
        :items-per-page-options="$itemsPerPageOptions"
      >
        <template v-slot:[`item.icon`]="{ item }">
          <router-link
            :to="{
              name: 'milkqualities_details',
              params: {
                primaryKeyLab: item.primaryKeyLab,
                sampleUrl: item.milkQualitySampleType == 'MONTHLY' ? 'ratingLab' : 'sample'
              }
            }"
            class="tableaction link"
            ><v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon></router-link
          ></template
        >
        <template v-slot:[`item.primaryKeyLab`]="{ item }">
          <router-link
            :to="{
              name: 'milkqualities_details',
              params: {
                primaryKeyLab: item.primaryKeyLab,
                sampleUrl: item.milkQualitySampleType
              }
            }"
            class="tableaction link"
            >{{ item.primaryKeyLab }}</router-link
          ></template
        >

        <template v-slot:[`item.errorCodeLab`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ props }">
              <span v-bind="props">{{ item.errorCodeLab }}</span>
            </template>
            <span>{{ showErrorCodeDescription(item.errorCodeLab) }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.milkQualityFarmAgisId`]="{ item }">
          <div>
            <template v-if="$privileges.has({ path: '/farms', permission: 'write' })">
              <router-link
                :to="{
                  name: 'masterdata_farms_edit',
                  params: { id: item.farmId, tab: 'overview' }
                }"
                class="tableaction link"
              >
                {{ item.milkQualityFarmAgisId }}
              </router-link>
            </template>
            {{ item['milkQualityFarmType' + this.$getUpLangKey()] }}
          </div>
        </template>
        <template v-slot:[`item.animalTypeId`]="{ item }">
          <div v-if="item.animalTypeId > 0">
            {{ animalTypes[item.animalTypeId - 1][this.$getLangKey()] }}
          </div>
        </template>
        <template v-slot:[`item.inhibitor`]="{ item }">
          <div v-if="inhibitors[item.inhibitor]">
            {{ inhibitors[item.inhibitor][this.$getLangKey()] }}
          </div>
        </template>
        <template v-slot:[`item.sampleStatus`]="{ item }">
          {{ showSampleStatus(item.sampleStatus) }}
        </template>
        <template v-slot:[`item.labDairyIdent`]="{ item }" v-if="$privileges.has({ path: '/dairies', permission: 'read' })">
          <a class="link" @click="goToMasterdata(item.labDairyIdent)">
            {{ item.labDairyIdent ? item.labDairyIdent : '' }}
          </a>
        </template>
        <template v-slot:[`item.farmAddress`]="{ item }">
          {{ item.milkQualityFarmName1 }} {{ item.milkQualityFarmName2 }},
          {{ item.milkQualityFarmAddressLocality }}
        </template>
        <template v-slot:[`item.sampleDate`]="{ item }">
          {{ item.sampleDate ? $moment(item.sampleDate).format('DD.MM.YYYY') : '-' }}
        </template>
        <template v-slot:[`item.download`]="{ item }">
          <v-btn @click="downloadLink(item)" x-small :loading="item.loading">
            <v-icon dbmblueprimary>mdi-tray-arrow-down</v-icon>
          </v-btn>
        </template>
      </v-data-table-server>
    </div>
  </v-container>
</template>
<script lang="ts">
import { emitter, apiURL } from '@/main'
import { fileCreator, showError } from '@/services/axios'
import { Term } from '@/services/term'
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import searchMilkQualityPeriod from '@/components/searchCards/searchMilkQualityPeriod.vue'
import searchFarm from '@/components/searchCards/searchFarm.vue'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import searchMilkQualityType from '@/components/searchCards/searchMilkQualityType.vue'
import searchMilkQualityValue from '@/components/searchCards/searchMilkQualityValue.vue'
import searchMilkQualityRating from '@/components/searchCards/searchMilkQualityRating.vue'
import { privileges } from '@/services/privileges'
import { useAnimalTypesStore } from '@/store/enums/AnimalTypesStore'
import { useInhibitorsStore } from '@/store/enums/InhibitorsStore'
import { useSampleStatusStore } from '@/store/enums/SampleStatusStore'

let defaultSortOrder = () => {
  let sort = []
  if (privileges.has({ path: '/milkQualities/producer', permission: 'read' }) && !privileges.has({ path: '/milkQualities', permission: 'allDomains' })) {
    sort.push('labIdentProducer,asc')
  }
  sort.push('farmAddress,asc')
  sort.push('milkQualityFarmAgisId,asc')
  return sort
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'milkqualities_read',
  data() {
    return {
      items: [],
      totalElements: 1,
      loading: false,
      downloading: false,
      reducedColumns: true,
      validAdditionalRule: []
    }
  },
  components: {
    searchMilkQualityPeriod,
    searchFarm,
    searchContact,
    searchMilkQualityType,
    searchMilkQualityValue,
    searchMilkQualityRating
  },
  computed: {
    animalTypes() {
      return useAnimalTypesStore().AnimalTypes
    },
    inhibitors() {
      return useInhibitorsStore().inhibitors
    },
    sampleStatuses() {
      return useSampleStatusStore().sampleStatuses
    },
    fields() {
      return [
        {
          title: ' ',
          value: 'icon',
          isVisible: true,
          isReduced: true,
          sortable: false
        },
        {
          // Download icon
          value: 'download',
          isVisible: true,
          isReduced: true,
          sortable: false,
          align: 'end'
        },
        {
          // Ref.-Lab.
          title: this.$t('search_milk_quality_table_lab_reference'),
          value: 'primaryKeyLab',
          isVisible: true,
          isReduced: false,
          sortable: true,
          align: 'left'
        },
        {
          // AGIS-ID Betrieb
          title: this.$t('search_milk_quality_table_farm_agis_id'),
          value: 'milkQualityFarmAgisId',
          isVisible: true,
          isReduced: true,
          sortable: true,
          align: 'left'
        },
        {
          // Lieferanten-Nr.
          title: this.$t('milk_qualities_table_number_lieferant'),
          value: 'labIdentProducer',
          isVisible: privileges.hasOneOf([{ path: '/milkQualities/producer', permission: 'read' }]),
          isReduced: true,
          sortable: true,
          align: 'left'
        },
        {
          // Name, Ort
          title: this.$t('milk_qualities_table_name_place'),
          value: 'farmAddress',
          isVisible: privileges.hasOneOf([{ path: '/milkQualities/csv', permission: 'read' }]),
          isReduced: true,
          sortable: true
        },
        {
          // Tierart
          title: this.$t('search_milk_quality_table_animal_type'),
          value: 'animalTypeId',
          isVisible: true,
          isReduced: true,
          sortable: true
        },
        {
          // Probetyp
          title: this.$t('search_milk_quality_table_sample_type'),
          value: 'milkQualitySampleType' + this.$getUpLangKey(),
          isVisible: true,
          isReduced: true,
          sortable: true
        },
        {
          // Datum
          title: this.$t('search_milk_quality_table_sample_date'),
          value: 'sampleDate',
          isVisible: true,
          isReduced: true,
          sortable: true
        },
        {
          title: this.$t('search_milk_quality_table_germs'),
          value: 'germs',
          isVisible: true,
          sortable: true,
          isReduced: true,
          align: 'end'
        },
        {
          title: this.$t('search_milk_quality_table_cells'),
          value: 'cells',
          isVisible: true,
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          title: this.$t('search_milk_quality_table_inhibitors'),
          value: 'inhibitor',
          isVisible: true,
          isReduced: true,
          sortable: true
        },
        {
          title: this.$t('search_milk_quality_table_freezing_point'),
          value: 'freezingPoint',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          title: this.$t('search_milk_quality_table_adaptation_temperature'),
          value: 'freezingPointAdapted',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Q75plus (°C)
          title: this.$t('search_milk_quality_table_q_plus_temperature'),
          value: 'freezingPointQ75',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Harnstoff (mg/100ml)
          title: this.$t('search_milk_quality_table_urea'),
          value: 'urea',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Fett (g/100g)
          title: this.$t('search_milk_quality_table_fat'),
          value: 'fat',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Eiweiss (g/100g)
          title: this.$t('search_milk_quality_table_protein'),
          value: 'protein',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },

        {
          // Freie Fettsäuren (mmol/10kg)
          title: this.$t('search_milk_quality_table_free_fatty_acids'),
          value: 'fattyAcid',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: false,
          sortable: true,
          align: 'end'
        },
        {
          // Buttersäuresporen (Sporen/l)

          title: this.$t('search_milk_quality_table_butyric_acid_spore'),
          value: 'clostridia',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: false,
          sortable: true,
          align: 'end'
        },
        {
          // Kasein (g/100g)

          title: this.$t('search_milk_quality_table_casein'),
          value: 'casein',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: false,
          sortable: true,
          align: 'end'
        },
        {
          // Kappa-Kasein B (mg/l)

          title: this.$t('milk_qualities_table_kappa_casein'),
          value: 'kappaCasein',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: false,
          sortable: true,
          align: 'end'
        },
        {
          // g Kappa-Kasein B / kg Kasein

          title: this.$t('milk_qualities_table_kappa_kasein_bgkg'),
          value: 'kappaCaseinBgKg',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: false,
          sortable: true,
          align: 'end'
        },
        {
          //Laktose(g/100g)

          title: this.$t('milk_qualities_table_lactose'),
          value: 'lactose',
          isVisible: privileges.hasOneOf([{ path: '/milkQualitySamples/content', permission: 'read' }]),
          isReduced: false,
          sortable: true,
          align: 'end'
        },
        {
          // Abzug/Zuschlag

          title: this.$t('search_milk_quality_table_deduction_addition'),
          value: 'deductibleTotalMonthly',
          isVisible: privileges.hasOneOf([{ path: '/milkQualityRatings', permission: 'read' }]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // MBH-ID

          title: this.$t('milk_qualities_table_mbh_ident'),
          value: 'labDairyIdent',
          isVisible: privileges.hasOneOf([{ path: '/milkQualities', permission: 'allColumns' }]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Milchsperre

          title: this.$t('search_milk_quality_table_delivery_stop'),
          value: 'deliveryStopLab',
          isVisible: true,
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Fehlercode

          title: this.$t('milk_qualities_table_errorcode'),
          value: 'errorCodeLab',
          isVisible: true,
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          //Anzahl Beanstandungen Keime

          title: this.$t('milk_qualities_table_complaints_germs'),
          value: 'complaintsGerms',
          isVisible: privileges.hasOneOf([
            {
              path: '/milkQualities/dataTableVisibility',
              permission: 'complaints'
            }
          ]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Anzahl Beanstandungen Zellen

          title: this.$t('milk_qualities_table_complaints_cells'),
          value: 'complaintsCells',
          isVisible: privileges.hasOneOf([
            {
              path: '/milkQualities/dataTableVisibility',
              permission: 'complaints'
            }
          ]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Anzahl Beanstandungen Hemmstoffe

          title: this.$t('milk_qualities_table_complaints_inhibitor'),
          value: 'complaintsInhibitor',
          isVisible: privileges.hasOneOf([
            {
              path: '/milkQualities/dataTableVisibility',
              permission: 'complaints'
            }
          ]),
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Milchkäufer

          title: this.$t('milk_qualities_table_milk_buyer'),
          value: 'dairyIdent',
          sortable: true,
          align: 'left',
          isReduced: true,
          isVisible: !privileges.hasOneOf([{ path: '/milkQualities/buyer', permission: 'hideIdent' }])
        },
        {
          // Probenstatus

          title: this.$t('milk_qualities_table_sample_status'),
          value: 'sampleStatus',
          isVisible: true,
          isReduced: true,
          sortable: true,
          align: 'end'
        },
        {
          // Laufnummer

          title: this.$t('milk_qualities_table_version_number'),
          value: 'versionNo',
          isVisible: privileges.hasOneOf([{ path: '/milkQualities', permission: 'allColumns' }]),
          isReduced: false,
          sortable: true,
          align: 'left'
        }
      ]
        .filter((el) => !this.reducedColumns || el.isReduced)
        .filter((el) => el.isVisible)
    },
    SearchMilkQualityPeriodStore() {
      return this.$store.state.SearchMilkQualityPeriodStore
    },
    term() {
      return [
        ...Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items)
      ]
    },
    params() {
      return {
        Quality_year: this.$store.state.SearchMilkQualityPeriodStore.Quality_Year,
        Quality_month: this.$store.state.SearchMilkQualityPeriodStore.Quality_Month,
        Quality_dateFrom: this.$store.state.SearchMilkQualityPeriodStore.Quality_DateFrom,
        Quality_dateUntil: this.$store.state.SearchMilkQualityPeriodStore.Quality_DateUntil,
        Quality_from: this.$store.state.SearchMilkQualityPeriodStore.Quality_From,
        Quality_until: this.$store.state.SearchMilkQualityPeriodStore.Quality_Until,
        Quality_animalTypeId: this.$store.state.SearchMilkQualityTypeStore.Quality_animalTypeId,
        Quality_sampleTypeId: this.$store.state.SearchMilkQualityTypeStore.Quality_sampleTypeId,
        Quality_sampleStatus: this.$store.state.SearchMilkQualityTypeStore.Quality_sampleStatus,
        Quality_errorCodeLab: this.$store.state.SearchMilkQualityTypeStore.Quality_errorCodeLab,
        Quality_hasInfo: this.$store.state.SearchMilkQualityTypeStore.Quality_hasInfo,
        Quality_dairyIdent: this.$store.state.SearchMilkQualityTypeStore.Quality_dairyIdent,
        Quality_primaryKeyLab: this.$store.state.SearchMilkQualityTypeStore.Quality_primaryKeyLab,
        Quality_germs: this.$store.state.SearchMilkQualityValueStore.Quality_Germs,
        Quality_cells: this.$store.state.SearchMilkQualityValueStore.Quality_Cells,
        Quality_inhibitor: this.$store.state.SearchMilkQualityValueStore.Quality_Inibitor,
        Quality_freezingPoint: this.$store.state.SearchMilkQualityValueStore.Quality_FreezingPoint,
        Quality_fattyAcid: this.$store.state.SearchMilkQualityValueStore.Quality_FattyAcid,
        Quality_clostridia: this.$store.state.SearchMilkQualityValueStore.Quality_Clostridia,
        Quality_thresholdExeededLab: this.$store.state.SearchMilkQualityRatingStore.Quality_ThresholdExeededLab,
        Quality_complaints: this.$store.state.SearchMilkQualityRatingStore.Quality_Complaints,
        Quality_complaintsCriterion: this.$store.state.SearchMilkQualityRatingStore.Quality_ComplaintsCriterion,
        Quality_deliveryStopLab: this.$store.state.SearchMilkQualityRatingStore.Quality_DeliveryStopLab
      }
    },
    query(): any {
      return {
        term: Term.stringify(this.term),
        ...this.params,
        ...this.getJavaPageOptions({ sort: defaultSortOrder() })
      }
    }
  },
  methods: {
    async exportforAuthority() {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + '/milkQualities/find/exportForAuthority', {
          params: {
            ...this.getJavaPageOptions(),
            term: Term.stringify(this.term),
            ...this.query
          },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        fileCreator(await response, 'milkQualityForAuthority.xlsx')
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    async exportSummaryReport(withOrWithoutDeductionUrl) {
      // we need an additional 'required' rule for valid from and valid until when downloading
      this.validAdditionalRule = [this.$rules.required]

      //check manually if required fields have valued because validAdditionalRule is not added in time for validate
      if (
        (await this.$refs.searchQuality.validate()).valid &&
        this.$store.state.SearchMilkQualityPeriodStore.Quality_From &&
        this.$store.state.SearchMilkQualityPeriodStore.Quality_From != '' &&
        this.$store.state.SearchMilkQualityPeriodStore.Quality_Until &&
        this.$store.state.SearchMilkQualityPeriodStore.Quality_Until != ''
      ) {
        this.downloading = true
        try {
          const result = await this.axios.get(apiURL + '/milkQualities/find/' + withOrWithoutDeductionUrl, {
            headers: { Accept: 'application/pdf' },
            responseType: 'blob',
            params: { ...this.query }
          })
          // if loading takes too long
          if ((await result.status) === 204) {
            emitter.emit('openDownloadInfoDialog')
          } else {
            fileCreator(await result, 'summaryReportMilkQuality.pdf')
          }
        } catch (e) {
          let responseObj = await e.response.data.text()
          showError({ response: { data: JSON.parse(responseObj) } })
        } finally {
          this.downloading = false
        }
      }
    },
    async exportAuthorityReport(showSurchargeColumn) {
      // we need an additional 'required' rule for valid from and valid until when downloading
      this.validAdditionalRule = [this.$rules.required]

      //check manually if required fields have valued because validAdditionalRule is not added in time for validate
      if (
        (await this.$refs.searchQuality.validate()).valid &&
        this.$store.state.SearchMilkQualityPeriodStore.Quality_From &&
        this.$store.state.SearchMilkQualityPeriodStore.Quality_From != '' &&
        this.$store.state.SearchMilkQualityPeriodStore.Quality_Until &&
        this.$store.state.SearchMilkQualityPeriodStore.Quality_Until != ''
      ) {
        this.downloading = true
        try {
          const result = await this.axios.get(apiURL + '/milkQualities/exportForAuthority', {
            headers: { Accept: 'application/pdf' },
            responseType: 'blob',
            params: {
              ...this.query,
              ...{ Show_surcharge_column: showSurchargeColumn }
            }
          })
          // if loading takes too long
          if ((await result.status) === 204) {
            emitter.emit('openDownloadInfoDialog')
          } else {
            fileCreator(await result, 'authorityReportMilkQuality.pdf')
          }
        } catch (e) {
          let responseObj = await e.response.data.text()
          showError({ response: { data: JSON.parse(responseObj) } })
        } finally {
          this.downloading = false
        }
      }
    },
    async goToMasterdata(labDairyIdent) {
      const dairyId = await this.getDairyId(labDairyIdent)
      this.$router
        .push({
          name: 'masterdata_dairies_edit',
          params: { id: dairyId.toString(), tab: 'overview' }
        })
        .catch(() => {
          /* duplicated route */
        })
    },
    async getDairyId(dairyIdent) {
      if (dairyIdent == null || dairyIdent == undefined || dairyIdent == '') {
        return
      }
      try {
        const response = await this.axios.get(
          apiURL + '/dairies/findByIdent',
          {
            params: { ident: dairyIdent }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        return response.data.id
      } catch (e) {
        showError(e)
      }
    },
    showErrorCodeDescription(errorCodeLab) {
      switch (errorCodeLab) {
        case 1:
          return this.$t('milk_qualities_table_errorcode_1_tooltip')
        case 2:
          return this.$t('milk_qualities_table_errorcode_2_tooltip')
        case 3:
          return this.$t('milk_qualities_table_errorcode_3_tooltip')
        case 4:
          return this.$t('milk_qualities_table_errorcode_4_tooltip')
        case 5:
          return this.$t('milk_qualities_table_errorcode_5_tooltip')
        case 6:
          return this.$t('milk_qualities_table_errorcode_6_tooltip')
        case 7:
          return this.$t('milk_qualities_table_errorcode_7_tooltip')
        case 8:
          return this.$t('milk_qualities_table_errorcode_8_tooltip')
        case 9:
          return this.$t('milk_qualities_table_errorcode_9_tooltip')
        default:
          return this.$t('milk_qualities_table_errorcode_0_tooltip')
      }
    },
    async downloadLink(item) {
      item.loading = true
      if (item.milkQualitySampleType === 'MONTHLY') {
        try {
          const result = await this.axios.get('/api/milkQualityRatingLabs/' + item.id + '/labReport', {
            headers: { Accept: 'application/pdf' },
            responseType: 'blob'
          })
          fileCreator(await result)
        } catch (e) {
          showError(e)
        } finally {
          item.loading = false
        }
      } else {
        try {
          const result = await this.axios.get('/api/milkQualitySamples/' + item.id + '/labReport', {
            headers: { Accept: 'application/pdf' },
            responseType: 'blob'
          })
          fileCreator(await result)
        } catch (e) {
          showError(e)
          console.log('e', e)
        } finally {
          item.loading = false
        }
      }
    },
    showSampleStatus(sampleStatus) {
      const statusToShow = this.sampleStatuses.find((el) => el.value === sampleStatus)
      return statusToShow?.[this.$getLangKey()] || ''
    },
    async search() {
      // reset the 'required' rule added to validfrom and validuntil that is needed for download
      this.validAdditionalRule = []
      if (!(await this.$refs.searchQuality.validate()).valid) {
        return false
      }
      this.$router
        .push({
          path: this.$route.path,
          query: this.query
        })
        .catch(() => {
          // route duplicated
          console.log('route duplicated')
          this.load()
        })
    },
    async load() {
      if ((await this.$refs.searchQuality.validate()).valid) {
        this.loading = true
        try {
          const response = await this.axios.get(apiURL + '/milkQualities/find', {
            params: this.query
          })
          const items = await response.data.content
          items.forEach((element) => (element.measurementDeviation = element.urea * 0.214))
          this.items = items
          this.totalElements = this.checkPage(response.data.totalElements)
        } catch (error) {
          showError(error)
        } finally {
          this.loading = false
        }
      }
    },
    reset() {
      this.$refs['search-milk-quality-period'].reset()
      this.$refs['search-farm'].reset()
      this.$refs['search-contact'].reset()
      this.$refs['search-milk-quality-type'].reset()
      this.$refs['search-milk-quality-value'].reset()
      this.$refs['search-milk-quality-rating'].reset()
    },
    async exportCsv() {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + '/milkQualities/find/export', {
          params: this.query,
          headers: {
            Accept: 'text/csv'
          },
          responseType: 'blob'
        })

        const href = URL.createObjectURL(response.data)

        // create "a" HTML element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', 'datenschnittstelle.csv') //or any other extension
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      } catch (e) {
        console.log('e', e)
        showError(e)
      } finally {
        this.downloading = false
      }
    },
    async exportWithDeductionOrAddition() {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + '/milkQualities/find/exportWithDeductions', {
          params: { ...this.query },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        // if loading takes too long
        if ((await response.status) === 204) {
          emitter.emit('openDownloadInfoDialog')
        } else {
          fileCreator(await response, 'abzugZuschlag.xlsx')
        }
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    async exportWithoutDeductionOrAddition() {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + '/milkQualities/find/exportWithoutDeductions', {
          params: { ...this.query },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        // if loading takes too long
        if ((await response.status) === 204) {
          emitter.emit('openDownloadInfoDialog')
        } else {
          fileCreator(await response, 'keinAbzugZuschlag.xlsx')
        }
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    async exportForAuthority() {
      this.downloading = true
      try {
        const response = await this.axios.get(apiURL + '/milkQualities/find/exportForAuthority', {
          params: { ...this.query },
          headers: {
            Accept: 'application/msexcel'
          },
          responseType: 'blob'
        })
        // if loading takes too long
        if ((await response.status) === 204) {
          emitter.emit('openDownloadInfoDialog')
        } else {
          fileCreator(await response, 'downloadAemter.xlsx')
        }
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    },
    async labReportsPdf() {
      this.downloading = true
      try {
        const result = await this.axios.get(apiURL + '/milkQualities/labReportsOfSelected', {
          headers: { Accept: 'application/pdf' },
          responseType: 'blob',
          params: { ...this.query }
        })
        // if loading takes too long
        if ((await result.status) === 204) {
          emitter.emit('openDownloadInfoDialog')
        } else {
          fileCreator(await result, 'labReportsOfSelected.pdf')
        }
      } catch (e) {
        let responseObj = await e.response.data.text()
        showError({ response: { data: JSON.parse(responseObj) } })
      } finally {
        this.downloading = false
      }
    }
  },
  watch: {
    '$route.query': {
      handler(newValue) {
        this.load()
      },
      deep: true
    }
  },
  beforeMount() {
    useInhibitorsStore().fetchInhibitors()
    useAnimalTypesStore().fetchAnimalTypes()
    useSampleStatusStore().fetchSampleStatuses()
  },
  mounted() {
    if (this.$route.query.size) {
      this.load()
    }
  }
})
</script>
<style scoped>
@media print {
  /* All your print styles go here */
  .container {
    padding: 0;
  }
}

.download-reference:hover {
  cursor: pointer;
}

.align-right {
  float: right;
}
</style>
